@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    scroll-behavior: smooth;
  }

  .h-d-screen {
    height: 100vh;
    height: 100dvh;
  }
}
